<template>
  <div>

    <div class="card card-default card-buy-now">
      <div class="card-body">
        <p>Klicke auf den Post um alle Details und Video aufzurufen</p>
        <div class="row">
          <div class="col-sm-4 mb-4" v-for="post in posts" :key="post.id">
            <post-preview :post="post"></post-preview>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default card-buy-now">

      <div class="card-body">

        <div class="mb-5" v-if="category">

          <h4 class="animated-fast fadeInDown"><i :class="category.icon" class="mr-2"></i> FAQ {{ category.name }}</h4>

          <accordion-card v-for="(entry, index) in category.questions" @opened="opened(entry)" :key="'index' + index" class="animated-fast fadeInLeft">
            <template v-slot:title>
              {{ entry.question }}
            </template>

            <markdown-parser :horizontal-line="false" :highlight="false" :table="false" :source="entry.answer"></markdown-parser>

          </accordion-card>

        </div>

      </div>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
import AccordionCard from "../../components/AccordionCard.vue";
import MarkdownParser from "@/components/Common/MarkdownParser.vue";
import PostPreview from "@/modules/News/components/PostPreview.vue";

export default {
  components: {
    PostPreview,
    MarkdownParser,
    AccordionCard,
  },
  data() {
    return {
      term: null,
      product: null,
      amount: 0,
      category: null,
      posts: []
    }
  },
  beforeCreate() {
    this.$api.get('support/categories/participation', {language: this.$i18n.locale}).then(response => {
      this.category = response.data;
    });
    return this.$api.get('news/posts/participation?language=' + this.$i18n.locale).then(response => {
      this.posts = response.data.data;
    });
  },
  methods: {
    opened(entry) {
      if (!entry.opened) {
        this.$api.post('support/question/' + entry.id + '/opened');
        entry.opened = true;
      }
    },
  }
}
</script>