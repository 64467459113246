<template>
    <router-link tag="a" :to="{name: 'News:Post', params: {vanity_url: post.vanity_url, id: post.id}}" class="card card-gray card-blog-post h-100 animated-fast fadeInRight">
        <div class="card-header p-0 overflow-hidden">
          <img class="mw-100" style="width: 100%" :src="post.banner_url + '?height=150&width=360'"/>
        </div>
        <div class="card-body pb-0">
          <div class="font-weight-bold">
            {{ post.title }}
          </div>
        </div>
        <div class="card-footer text-white">
          <span class="date pl-2">{{ $t('common.view.dashboard.blog.posted') }}: {{ post.published_at | moment('L') }}</span>
          <span class="open pl-2">{{ $t('common.view.dashboard.blog.open') }}</span>
        </div>
    </router-link>
</template>
<style lang="scss">


</style>
<script>
export default {
    props: ['post'],
    data() {
        return {

        }
    },
    created() {


    },
    methods: {

    }
}
</script>
